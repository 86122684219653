import { localize } from "render/context/InternationalizationContext";

const CheckList = {
  Caffeine: localize({
    en_GB: <>Avoid caffeine 2 hours in advance</>,
    sv_SE: <>Undvik koffein 2 timmar innan </>,
  }),
  Eating: localize({
    en_GB: <>You can eat, but avoid large portions</>,
    sv_SE: <>Du kan äta, men undvik gärna stora portioner</>,
  }),
  Exercises: localize({
    en_GB: <>You can exercise normally</>,
    sv_SE: <>Du kan träna som vanligt</>,
  }),
  Skin: localize({
    en_GB: <>For the skin scan, avoid wearing patterned or baggy underwear</>,
    sv_SE: (
      <>
        Undvik att bära mönstrade eller löst sittande underkläder för din hud
        scan
      </>
    ),
  }),
};

const Title = {
  NotReady: {
    Header: localize({
      en_GB: <>Are you ready?</>,
      sv_SE: <>Är du redo för besöket?</>,
    }),
    SubHeader: localize({
      en_GB: <>Please complete your profile before your scan.</>,
      sv_SE: <>Slutför din profil innan ditt scan.</>,
    }),
  },
  Ready: {
    Header: localize({
      en_GB: <>You're all set</>,
      sv_SE: <>Du är redo för ditt besök</>,
    }),
    SubHeader: localize({
      en_GB: (
        <>
          You're ready for your visit. We're looking forward to seeing you soon.
        </>
      ),
      sv_SE: <>Vi ser fram emot att träffa dig.</>,
    }),
  },
};

const TodoList = {
  Done: {
    CompleteOnboarding: localize({
      en_GB: <>Answered health questions</>,
      sv_SE: <>Besvarat hälsoformulär</>,
    }),
    IdentityVerification: localize({
      en_GB: <>Verified identity</>,
      sv_SE: <>Verifierat identitet</>,
    }),
    Passkey: localize({
      en_GB: <>Setup secure Passkey</>,
      sv_SE: <>Lagt till Passkey</>,
    }),
    RenewStudies: localize({
      en_GB: <>Agreed to clinical studies</>,
      sv_SE: <>Samtyckt till studier</>,
    }),
  },
  Todo: {
    CompleteOnboarding: localize({
      en_GB: <>Answer health questions</>,
      sv_SE: <>Besvara hälsoformulär</>,
    }),
    IdentityVerification: localize({
      en_GB: <>Verify identity</>,
      sv_SE: <>Verifiera identitet</>,
    }),
    Passkey: localize({
      en_GB: <>Setup secure Passkey</>,
      sv_SE: <>Lägg till Passkey</>,
    }),
    RenewStudies: localize({
      en_GB: <>Agree to clinical studies</>,
      sv_SE: <>Samtyck till studier</>,
    }),
  },
};

export const Trans = { CheckList, Title, TodoList };
