import { Typography } from "@cur8/maneki";
import { DateReadable } from "render/ui/format/DateReadable";
import { Summary } from "render/ui/presentation/doctor/Summary";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { Button } from "render/ui/trigger/Button";
import { PostScanShareViewEntrypoint } from "render/views/PostScanShareView/components/PostScanShareViewEntrypoint";
import { useSummaries } from "render/views/Report/AppointmentDataView/hooks/useSummaries";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

type SummariesTabProps = {
  onGoToHistory: () => void;
  onGoToScanData: () => void;
};

export function SummariesTab({
  onGoToHistory,
  onGoToScanData,
}: SummariesTabProps) {
  const summaries = useSummaries();
  const latestSummary = summaries.at(0);

  return (
    <div className={styles.SummariesTab} data-testid="summaries-tab">
      <div className={styles.header}>
        <div>
          <Typography variant="display-s">
            <Trans.Title />
          </Typography>
          {latestSummary?.lastUpdatedAt && (
            <Typography variant="body-s" color="subtle">
              <Trans.LastUpdateOn
                date={<DateReadable date={latestSummary.lastUpdatedAt} />}
              />
            </Typography>
          )}
        </div>
        <PostScanShareViewEntrypoint />
      </div>
      <div className={styles.messages}>
        {latestSummary?.messages.map((message) => (
          <Summary
            key={message.id}
            date={message.date}
            doctorUri={displayDocName(message.text, message.doctorUri)}
            text={message.text}
            title={message.title}
          />
        ))}
      </div>
      <div className={styles.buttons}>
        <ActionButton variant="suggestion" onClick={onGoToScanData}>
          <Trans.ScanData />
        </ActionButton>
        {
          // only show the button if there's more than one summary
          // because the "latest" will not show on that view
          summaries.length > 1 && (
            <Button variant="outlined" onClick={onGoToHistory}>
              <Trans.ReportHistory />
            </Button>
          )
        }
      </div>
    </div>
  );
}

function displayDocName(text: string, uri: string | undefined) {
  // TODO: this is a hack, replace with something better when we can better differentiate between different types of follow-up messages.

  if (
    text.includes("our dermatologist has done an additional review") ||
    text.includes("vår hudläkare har gjort en extra kontroll")
  ) {
    return null;
  }
  return uri;
}
