import { Patient } from "@cur8/rich-entity";
import { calcAge } from "lib/age";
import type { Metric } from "lib/api/metrics";
import { patientScore2 } from "lib/scores/score2/score2";
import { desc } from "lib/sort";
import type { DateTime } from "luxon";

export type Score2 = {
  score: number;
};

export type Score2Factors = {
  patient: Patient | undefined;
  date: DateTime;
  smoking: Metric<"lifestyle.smoking-habit"> | undefined;
  brachial: {
    left: Metric<"cardio.brachial_pressure.left"> | undefined;
    right: Metric<"cardio.brachial_pressure.right"> | undefined;
  };
  nonHDL: Metric<"bloodwork.non_hdl"> | undefined;
};

export function calcScore2({
  patient,
  date,
  nonHDL,
  brachial: { left, right },
  smoking,
}: Score2Factors): Score2 | undefined {
  if (!patient || !nonHDL || !smoking) {
    return;
  }

  const highestBrachialPressure = [left, right]
    .filter(notNullish)
    .toSorted(desc((m) => m.unit.systolic.mmHg))
    .at(0);

  if (highestBrachialPressure == null) {
    return;
  }

  const dateOfBirth = patient.dateOfBirth;
  const age = dateOfBirth ? calcAge(dateOfBirth, date) : NaN;

  const score2 = patientScore2({
    age,
    metric: {
      brachialPressure: highestBrachialPressure,
      nonHDL: nonHDL,
      smoking: smoking,
    },
    sex: patient.sex,
  });

  return {
    score: score2 ?? NaN,
  };
}

function notNullish<T>(value: T | null | undefined): value is T {
  return value != null;
}
