import { PathRoute, useNav, useRouter } from "@pomle/react-router-paths";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { ReactComponent as QuestionIcon } from "assets/icons/24x24/24x24_chat.svg";
import { ReactComponent as ExIcon } from "assets/icons/24x24/24x24_close.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { usePopup } from "render/context/PopupContext";
import { useLatestScanQuery } from "render/hooks/api/queries/useLatestScanQuery";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { useTracking } from "render/hooks/useTracking";
import { paths } from "render/routes/paths";
import { ErrorBoundary } from "render/ui/format/ErrorBoundary";
import { Backdrop } from "render/ui/layout/Backdrop";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Nav } from "render/ui/layout/Nav";
import { IconButton } from "render/ui/trigger/IconButton";
import { MetricABIDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricABI/Details";
import { MetricAnklePressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricAnklePressure/Details";
import { MetricBMIDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBMI/Details";
import { MetricBasophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBasophils/Details";
import { MetricBloodOxygenDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodOxygen/Details";
import { MetricBloodPressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodPressure";
import { MetricHDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkHdl/Details";
import { MetricLDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkLdl/Details";
import { MetricNonHDLDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricBloodworkNonHdl/Details";
import { MetricHsCRPDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCRP/Details";
import { MetricCholesterolDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricCholesterol/Details";
import { MetricEosinophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEosinophils/Details";
import { MetricEyePressureDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricEyePressure/Details";
import { MetricGlucoseDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGlucose/Details";
import { MetricGripStrengthDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricGripStrength/Details";
import { MetricHbA1cDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHBA1C/Details";
import { MetricHaemoglobinDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHaemoglobin/Details";
import { MetricHeartRateDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricHeartRate/Details";
import { MetricLymphocytesDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricLymphocytes/Details";
import { MetricNeutrophilsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricNeutrophils/Details";
import { MetricSkinDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricSkinLesions";
import { MetricTriglyceridesDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricTriglycerides/Details";
import { MetricWeightDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWeight/Details";
import { MetricWhiteBloodCellsDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/MetricWhiteBloodCells/Details";
import { ReportHistory } from "render/views/Report/AppointmentDataView/components/ReportHistory";
import { ReportHistoryItem } from "render/views/Report/AppointmentDataView/components/ReportHistoryItem";
import { AvatarSection } from "./components/AvatarSection";
import { ScanDataTab } from "./components/ScanDataTab";
import { SummariesTab } from "./components/SummariesTab/SummariesTab";
import styles from "./styles.module.sass";
import { reportEvent } from "./tracking";
import { Trans } from "./trans";

const MOBILE_BREAKPOINT = 980;
const MOBILE_OFFSET = 100;
const DESKTOP_OFFSET = 60 * 2;

const MetricToRoute = [
  {
    component: MetricBloodPressureDetails,
    metric: "bloodPressure",
    route: paths.bloodPressureMetric,
  },
  {
    component: MetricAnklePressureDetails,
    metric: "ankle",
    route: paths.anklePressureMetric,
  },
  {
    component: MetricABIDetails,
    metric: "abi",
    route: paths.abiMetric,
  },
  {
    component: MetricBloodOxygenDetails,
    metric: "bloodOxygen",
    route: paths.bloodOxygenMetric,
  },
  {
    component: MetricHeartRateDetails,
    metric: "heartRate",
    route: paths.heartRateMetric,
  },
  {
    component: MetricCholesterolDetails,
    metric: "cholesterol",
    route: paths.cholesterolMetric,
  },
  {
    component: MetricLDLDetails,
    metric: "ldl",
    route: paths.ldlMetric,
  },
  {
    component: MetricHDLDetails,
    metric: "hdl",
    route: paths.hdlMetric,
  },
  {
    component: MetricNonHDLDetails,
    metric: "NonHDL",
    route: paths.nonHDLMetric,
  },
  {
    component: MetricTriglyceridesDetails,
    metric: "triglycerides",
    route: paths.triglyceridesMetric,
  },
  {
    component: MetricWhiteBloodCellsDetails,
    metric: "whiteBloodCell",
    route: paths.whiteBloodCellMetric,
  },
  {
    component: MetricNeutrophilsDetails,
    metric: "neutrophil",
    route: paths.neutrophilMetric,
  },
  {
    component: MetricBasophilsDetails,
    metric: "basophil",
    route: paths.basophilMetric,
  },
  {
    component: MetricEosinophilsDetails,
    metric: "eosinophil",
    route: paths.eosinophilMetric,
  },
  {
    component: MetricLymphocytesDetails,
    metric: "lymphocyte",
    route: paths.lymphocyteMetric,
  },
  {
    component: MetricHsCRPDetails,
    metric: "hsCRP",
    route: paths.hsCRPMetric,
  },
  {
    component: MetricHbA1cDetails,
    metric: "hbA1c",
    route: paths.hbA1cMetric,
  },
  {
    component: MetricHaemoglobinDetails,
    metric: "haemoglobin",
    route: paths.haemoglobinMetric,
  },
  {
    component: MetricGlucoseDetails,
    metric: "glucose",
    route: paths.glucoseMetric,
  },
  {
    component: MetricWeightDetails,
    metric: "weight",
    route: paths.weightMetric,
  },
  {
    component: MetricBMIDetails,
    metric: "bmi",
    route: paths.bmiMetric,
  },
  {
    component: MetricGripStrengthDetails,
    metric: "gripStrength",
    route: paths.gripStrengthMetric,
  },
  {
    component: MetricEyePressureDetails,
    metric: "eyePressure",
    route: paths.eyePressureMetric,
  },
  {
    component: MetricSkinDetails,
    metric: "skin",
    route: paths.skinMetric,
  },
];

export function ReportView() {
  const router = useRouter();
  const { trackEvent } = useTracking();
  const [transitioning, setTransitioning] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number | undefined>(
    undefined
  );
  const headerRef = useRef<HTMLDivElement>(null);
  const container = useRef<HTMLDivElement>(null);
  const nav = {
    home: useNav(paths.root),
    summary: useNav(paths.appointmentSummary),
    summaryData: useNav(paths.appointmentSummaryData),
    summaryHistory: useNav(paths.appointmentSummaryHistory),
  };
  const popUpDialogContext = usePopup();
  const contactUsPopup = useContactUsPopup();
  const latestSkinScanQuery = useLatestScanQuery("skin/");

  const avatarMaxHeight = useMemo(() => {
    if (headerHeight == null) {
      return null;
    }
    const offSet =
      document.body.clientWidth > MOBILE_BREAKPOINT
        ? DESKTOP_OFFSET
        : MOBILE_OFFSET;

    return Math.min(document.body.clientHeight - headerHeight - offSet, 1000);
  }, [headerHeight]);

  useEffect(() => {
    if (!transitioning) {
      return;
    }

    const id = window.setTimeout(() => {
      setTransitioning(false);

      if (!container.current) {
        return;
      }
      if (!headerRef.current) {
        return;
      }

      if (document.body.clientWidth > MOBILE_BREAKPOINT) {
        container.current.scrollTo({ top: 0 });
        return;
      }

      const header = headerRef.current.clientHeight;
      const top = window.document.body.clientHeight - header - MOBILE_OFFSET;

      if (container.current.scrollTop < top) {
        return;
      }

      container.current.scrollTo({ top });
    }, 400);

    return () => window.clearTimeout(id);
  }, [transitioning]);

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }
    setHeaderHeight(headerRef.current.clientHeight);
  }, []);

  const isHeaderActive = useMemo(() => {
    const routes = [
      paths.appointmentSummary,
      paths.appointmentSummaryData,
      paths.appointmentSummaryHistory,
      paths.appointmentSummaryHistoryItem,
    ] as const;

    return routes.some((route) => {
      const match = route.match(router.location.pathname);
      const result = match === 0;
      return result;
    });
  }, [router.location]);

  const latestSkinScan = latestSkinScanQuery.data?.[0];

  return (
    <ViewStack>
      <FullScreenPageLayout ref={container} aria-hidden={!isHeaderActive}>
        <div className={styles.contentContainer}>
          <div
            ref={headerRef}
            data-active={isHeaderActive}
            className={styles.headerSticky}
            data-blured={popUpDialogContext.isActive}
          >
            <LogoHeader
              leftElement={
                <IconButton
                  onClick={nav.home.on({})}
                  icon={<ExIcon />}
                  ariaLabel={Trans.Icons.Close()}
                />
              }
              rightElement={
                <IconButton
                  icon={<QuestionIcon display="block" />}
                  onClick={() => contactUsPopup.emit()}
                  ariaLabel={Trans.Icons.ContactUs()}
                />
              }
            />
            <div className={styles.navTabsContainer}>
              <div className={styles.navTabs}>
                <Nav>
                  <Nav.Item
                    label={<Trans.Tabs.Summary />}
                    path={paths.appointmentSummary}
                    params={{}}
                    onClick={() => {
                      setTransitioning(true);
                      trackEvent(reportEvent.navigate("summary"));
                    }}
                  />
                  <Nav.Item
                    label={<Trans.Tabs.ScanData />}
                    path={paths.appointmentSummaryData}
                    params={{}}
                    onClick={() => {
                      setTransitioning(true);
                      trackEvent(reportEvent.navigate("biomarkers"));
                    }}
                  />
                </Nav>
              </div>
            </div>
          </div>
          <div className={styles.flex}>
            <div className={styles.content}>
              {avatarMaxHeight && (
                <>
                  <ErrorBoundary
                    fallback={() => <div style={{ height: avatarMaxHeight }} />}
                  >
                    <AvatarSection
                      height={avatarMaxHeight}
                      skinScan={latestSkinScan}
                    />
                  </ErrorBoundary>
                  <div
                    className={styles.tabContentContainer}
                    data-active={isHeaderActive}
                  >
                    {latestSkinScan && (
                      <>
                        <PathRoute path={paths.appointmentSummary}>
                          {(match) => (
                            <div
                              data-transitioning={transitioning}
                              data-active={
                                !!match &&
                                !window.location.pathname.startsWith(
                                  paths.appointmentSummaryData.name
                                )
                              }
                              className={styles.tabContent}
                            >
                              <SummariesTab
                                onGoToScanData={() => {
                                  setTransitioning(true);
                                  trackEvent(
                                    reportEvent.navigate("biomarkers")
                                  );
                                  nav.summaryData.go({});
                                }}
                                onGoToHistory={() => {
                                  trackEvent(reportEvent.navigate("history"));
                                  nav.summaryHistory.go({});
                                }}
                              />
                            </div>
                          )}
                        </PathRoute>
                        <PathRoute path={paths.appointmentSummaryData}>
                          {(match) => (
                            <div
                              data-transitioning={transitioning}
                              data-active={!!match}
                              className={styles.tabContent}
                            >
                              <ScanDataTab
                                latestScanDate={latestSkinScan.timestamp}
                              />
                            </div>
                          )}
                        </PathRoute>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </FullScreenPageLayout>
      {MetricToRoute.map(({ metric, route, component: Component }) => (
        <PathRoute path={route} key={metric}>
          {(match) => (
            <Backdrop
              active={!!match}
              kind="transparent"
              size="half"
              onOutsideClick={nav.summaryData.on({})}
            >
              <Sticky>{!!match && <Component />}</Sticky>
            </Backdrop>
          )}
        </PathRoute>
      ))}
      <PathRoute path={paths.appointmentSummaryHistory}>
        {(match) => (
          <Backdrop
            active={!!match}
            kind="subtle"
            onOutsideClick={nav.summary.on({})}
          >
            <Sticky>{!!match && <ReportHistory />}</Sticky>
          </Backdrop>
        )}
      </PathRoute>
      <PathRoute path={paths.appointmentSummaryHistoryItem}>
        {(match) =>
          match && (
            <Backdrop
              active
              kind="transparent"
              onOutsideClick={nav.summaryHistory.on({})}
            >
              <Sticky>
                <ReportHistoryItem messageId={match.params.messageId} />
              </Sticky>
            </Backdrop>
          )
        }
      </PathRoute>
    </ViewStack>
  );
}
