import { APITypesV1 } from "@cur8/api-client";
import { MetricName, Unit, toDimensions, toUnit } from "@cur8/measurements";
import { Measurement, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";

export type MetricGroup = Partial<Record<string, Metric>>;
export type Metric<M extends MetricName = MetricName> = {
  name: M;
  measurement: Measurement;
  unit: Unit<M>;
};

export function createMetric<M extends MetricName>(
  metricName: M,
  unit: Unit<M>
): Metric<M> {
  const now = DateTime.now();

  const measurement: Metric<M>["measurement"] = {
    dimensions: toDimensions(metricName, unit),
    generator: "cur8:patient:x",
    id: "",
    metricName,
    sourceUri: "",
    timestampEnd: now,
    timestampStart: now,
  };

  return {
    measurement,
    name: metricName,
    unit,
  };
}

function toMetric<M extends MetricName>(
  metricName: M,
  measurement: Measurement
): Metric<M> {
  return {
    measurement: measurement as Metric<M>["measurement"],
    name: metricName,
    unit: toUnit<M>(metricName, measurement.dimensions),
  };
}

type Query<M extends MetricName> = APITypesV1.MeasurementQueryParams & {
  metricName: M;
};

export function queryMetrics<M extends MetricName>(
  api: APIClient["measurement"],
  query: Query<M>,
  options: { signal?: AbortSignal }
) {
  const req = api.queryMeasurements(query);

  options.signal?.addEventListener("abort", () => req.abandon());

  return req.result.then((response) => {
    return response.items.map(fromAPI.toMeasurement).map((measurement) => {
      return toMetric<M>(query.metricName, measurement);
    });
  });
}
