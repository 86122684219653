import { Typography } from "@cur8/maneki";
import { Slot } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ReactComponent as CheckMarkIcon } from "assets/icons/16x16/16x16_checkmark.svg";
import { ReactComponent as CrossIcon } from "assets/icons/24x24/24x24_close.svg";
import { ReactComponent as LogoIcon } from "assets/logo.svg";
import { useMemo } from "react";
import { paths } from "render/routes/paths";
import { Accordion } from "render/ui/presentation/AccordionV2";
import BookingDetailSquareImage from "./assets/img/booking-detail-square.webp";
import BookingDetailWideImage from "./assets/img/booking-detail-wide.webp";
import DoctorImage from "./assets/img/doctor.webp";
import { CompleteProfileButton } from "./components/CompleteProfileButton";
import { StepsSection } from "./components/StepsSection";
import { TipsSection } from "./components/TipSection";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

interface AppointmentInfoSectionProps {
  slot: Slot;
  visitId: string;
}

export function AppointmentInfoSection({
  slot,
  visitId,
}: AppointmentInfoSectionProps) {
  const nav = {
    root: useNav(paths.root),
  };
  const timeZoneId = slot.room?.site?.timeZoneId;
  const date = useMemo(
    () => slot.startTime.setZone(timeZoneId),
    [slot.startTime, timeZoneId]
  );
  const startTime = useMemo(
    () => slot.startTime.setZone(timeZoneId),
    [slot.startTime, timeZoneId]
  );
  const endTime = useMemo(
    () => slot.endTime.setZone(timeZoneId),
    [slot.endTime, timeZoneId]
  );
  const duration = useMemo(
    () => endTime.diff(startTime, "minute").minutes,
    [startTime, endTime]
  );

  const address = slot.room?.site?.address;
  const mapQuery =
    address &&
    queryString({
      near: `${address.postalCode}, ${address.city}, ${address.country}`,
      q: `${address.name}, ${address.street}`,
    });

  return (
    <div className={styles.AppointmentInfoSection}>
      <section className={styles.head}>
        <picture>
          <source
            srcSet={BookingDetailSquareImage}
            media="(max-width: 980px)"
          />
          <source srcSet={BookingDetailWideImage} />
          <img className={styles.image} alt="room" width="3040" height="1200" />
        </picture>
        <div className={styles.header}>
          <div className={styles.logo}>
            <PathLink className={styles.link} to={nav.root.to({})}>
              <CrossIcon />
            </PathLink>
            <LogoIcon />
          </div>
          <div className={styles.title}>
            <Typography variant="display-s">
              <Trans.Title />
            </Typography>
            <div className={styles.badge}>
              <CheckMarkIcon />
              <Typography variant="eyebrow-m">
                <Trans.Status location={slot.room?.site?.siteName ?? ""} />
              </Typography>
            </div>
          </div>
        </div>
      </section>

      <div className={styles.content}>
        <div className={styles.body}>
          <section className={styles.sectionTime}>
            <div className={styles.date}>
              <Typography variant="numeral-s">{date.toFormat("dd")}</Typography>

              <Typography variant="body-m" color="subtle">
                {date.toFormat("MMMM")}, {date.toFormat("EEEE")}
              </Typography>
            </div>
            <div className={styles.hour}>
              <Typography variant="numeral-s">
                {startTime.toFormat("HH:mm")}
              </Typography>
              <Typography variant="body-m" color="subtle">
                <Trans.EndTime
                  duration={duration}
                  endTime={endTime.toFormat("HH:mm")}
                />
              </Typography>
            </div>
          </section>

          <section className={styles.stepsSection}>
            <div className={styles.stepsContent}>
              <div className={styles.stepsContainer}>
                <StepsSection slot={slot} visitId={visitId} />
              </div>
              <div className={styles.tipsContainer}>
                <TipsSection />
              </div>
            </div>
          </section>

          <div className={styles.separator} />

          <section className={styles.sectionLocation}>
            <div className={styles.findUs}>
              <Trans.FindUs.Title />
            </div>
            <a
              className={styles.address}
              target="_blank"
              rel="noreferrer"
              href={`http://google.com/maps/?${mapQuery}`}
            >
              {address?.street}
            </a>
            <div className={styles.arival}>
              <Trans.FindUs.Description />
            </div>
          </section>

          <section className={styles.sectionDoctorImage}>
            <img src={DoctorImage} alt="doctor" width="100%" height="100%" />
          </section>

          <section className={styles.sectionFAQ}>
            <Typography variant="title-m">
              <Trans.FAQ.Title />
            </Typography>
            <Accordion>
              <Accordion.Item
                header={<Trans.FAQ.Skin.Header />}
                text={<Trans.FAQ.Skin.Body />}
                id={Trans.FAQ.Skin.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Heart.Header />}
                text={<Trans.FAQ.Heart.Body />}
                id={Trans.FAQ.Heart.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Blood.Header />}
                text={<Trans.FAQ.Blood.Body />}
                id={Trans.FAQ.Blood.Header()}
              />
              <Accordion.Item
                header={<Trans.FAQ.Consultation.Header />}
                text={<Trans.FAQ.Consultation.Body />}
                id={Trans.FAQ.Consultation.Header()}
              />
            </Accordion>
          </section>
          <section className={styles.mobileProfileButton}>
            <CompleteProfileButton slot={slot} visitId={visitId} />
          </section>
        </div>
      </div>
    </div>
  );
}

function queryString(obj: Record<string, string>) {
  return Object.entries(obj)
    .map(([key, value]) => [key, encodeURIComponent(value)].join("="))
    .join("&");
}
