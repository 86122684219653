import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { Summary } from "render/ui/presentation/doctor/Summary";
import { IconButton } from "render/ui/trigger/IconButton";
import { useSummaries } from "render/views/Report/AppointmentDataView/hooks/useSummaries";
import styles from "./styles.module.sass";

type ReportHistoryItemProps = {
  messageId: string;
};

export function ReportHistoryItem({ messageId }: ReportHistoryItemProps) {
  const summaries = useSummaries();
  const nav = {
    history: useNav(paths.appointmentSummaryHistory),
  };

  const message = summaries
    .flatMap((s) => s.messages)
    .find((m) => m.id === messageId);

  // when data is loaded and message is not found == bad URL
  if (summaries.length && !message) {
    nav.history.go({});
  }

  // wait for data to load
  if (!message) {
    return;
  }

  return (
    <FullScreenPageLayout background="porcelain">
      <LogoHeader
        leftElement={
          <IconButton onClick={nav.history.on({})} icon={<ArrowLeftIcon />} />
        }
        sticky
      />
      <div className={styles.ReportHistoryItem}>
        <Summary
          date={message.date}
          doctorUri={message.doctorUri}
          text={message.text}
          title={message.title}
        />
      </div>
    </FullScreenPageLayout>
  );
}
