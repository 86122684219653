import { RangeLabel, RiskRange } from "@cur8/health-risks-calc";
import React from "react";
import { ChartRange } from "../types";
import { Trans } from "./trans";

const LABEL_MAP: Record<RangeLabel, React.ReactNode> = {
  balanced: <Trans.Balanced />,
  diabetes: <Trans.Diabetes />,
  elevated: <Trans.Elevated />,
  high: <Trans.High />,
  low: <Trans.Low />,
  mild: <Trans.Mild />,
  moderate: <Trans.Moderate />,
  normal: <Trans.Normal />,
  optimal: <Trans.Optimal />,
  "pre-diabetes": <Trans.PreDiabetes />,
  unbalanced: <Trans.Unbalanced />,
  unknown: <Trans.Unknown />,
  "very high": <Trans.VeryHigh />,
  "very low": <Trans.VeryLow />,
  "very-unbalanced": <Trans.VeryUnbalanced />,
};

export function toChartRanges(ranges: readonly RiskRange[]): ChartRange[] {
  return ranges.map((range) => {
    const isFromInfinity = !isFinite(range.from);
    const isToInfinity = !isFinite(range.to);

    return {
      from: isFromInfinity ? range.to * 0.9 : range.from,
      label: range.label ? LABEL_MAP[range.label] : "-",
      risk: range.risk,
      to: isToInfinity ? range.from * 2.2 : range.to,
    };
  });
}

export function capToMaxRanges({
  ranges,
  current,
  previous,
}: {
  ranges: ChartRange[];
  current?: number;
  previous?: number;
}) {
  if (!current) {
    return ranges.slice(0, 3);
  }
  const sortedRanges = ranges.toSorted((a, b) => a.to - b.to);

  const activeRange = sortedRanges.find((range) => {
    return current >= range.from && current < range.to;
  });

  const previousRange =
    previous != null
      ? sortedRanges.find((range) => {
          return previous >= range.from && previous < range.to;
        })
      : undefined;
  const previousIndex =
    previousRange != null ? sortedRanges.indexOf(previousRange) : undefined;
  const currentIndex = activeRange ? sortedRanges.indexOf(activeRange) : 0;

  let start: number;
  let end: number;
  function calculateStart() {
    if (previousIndex != null && previousIndex <= currentIndex) {
      if (currentIndex - previousIndex > 1) {
        return previousIndex;
      }
      return previousIndex - 1;
    }
    if (currentIndex > 2) {
      return currentIndex - 2;
    }
    return currentIndex - 1;
  }

  // if gap between the values is greater than two, then show all the ranges necessary
  if (previousIndex != null && Math.abs(currentIndex - previousIndex) > 2) {
    start = previousIndex < currentIndex ? previousIndex : currentIndex;
    end = Math.min(
      sortedRanges.length,
      previousIndex > currentIndex ? previousIndex : currentIndex
    );
  }
  // else show three ranges if possible, expect at the top end
  else {
    start = Math.max(0, calculateStart());
    end = Math.min(
      sortedRanges.length,
      currentIndex >= start + 2 ? currentIndex : start + 2
    );
  }

  return sortedRanges.slice(start, end + 1);
}
