import { Cholesterol } from "@cur8/health-risks-calc";
import { useQueryParams } from "@pomle/react-router-paths";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useCholesterolMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricCholesterolDetails() {
  const { data } = useCholesterolMetric();
  const { data: patient } = usePatientQuery();
  const age = patient?.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!data || !patient) {
    return undefined;
  }

  const { cur: current, prev: previous } = getMetricHistory(data, scanNum);

  const riskRanges = Cholesterol.rangesFor({ age });

  const values = {
    metricRating: current && riskRanges.findRisk(current.unit),
    previousValue: previous?.unit["mmol/L"],
    ranges: toChartRanges(riskRanges.entries),
    scanDate: current?.measurement.timestampStart,
    subLabel: "mmol/L",
    value: current?.unit["mmol/L"],
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
    />
  );
}
