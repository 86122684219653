import { Typography } from "@cur8/maneki";
import { useNav } from "@pomle/react-router-paths";
import { ReactComponent as ArrowBackIcon } from "assets/icons/24x24/24x24_arrow_left.svg";
import { ReactComponent as ChatIcon } from "assets/icons/24x24/24x24_chat.svg";
import { useCallback, useState } from "react";
import { useContactUsPopup } from "render/hooks/popups/useContactUsPopup";
import { paths } from "render/routes/paths";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader";
import { ActionButton } from "render/ui/trigger/ActionButton";
import { IconButton } from "render/ui/trigger/IconButton";
import { useCheckoutContext } from "render/views/checkout/_CheckoutContext/CheckoutContext";
import { useSubmitCardPaymentMutation } from "render/views/checkout/_CheckoutContext/hooks/payments/useSubmitCardPaymentMutation";
import { ProductPrice } from "render/views/checkout/components/ProductPrice";
import { PaymentForm } from "./components/PaymentForm";
import { PaymentFormSkeleton } from "./components/PaymentFormSkeleton";
import styles from "./styles.module.sass";
import { Trans } from "./trans";

export function CardPayment() {
  const contactUsPopup = useContactUsPopup();
  const {
    checkout,
    cart,
    stripe,
    elements,
    discountApplied,
    discountCode,
    fullPrice,
  } = useCheckoutContext();
  const nav = {
    checkout: useNav(paths.checkout),
  };
  const [isFormReady, setIsFormReady] = useState(false);
  const onBackClick = useCallback(() => nav.checkout.set({}), [nav.checkout]);
  const submitCardPaymentMutation = useSubmitCardPaymentMutation();
  const submitCardPaymentMutationMutate = submitCardPaymentMutation.mutate;

  const formControls = {
    isDisabled: !isFormReady || submitCardPaymentMutation.isPending,
    isPending: submitCardPaymentMutation.isPending,
    isReady: isFormReady,
    onReady: useCallback(() => {
      setIsFormReady(true);
    }, []),
    onSubmit: useCallback(async () => {
      if (stripe == null || elements == null || checkout == null) {
        return;
      }
      return submitCardPaymentMutationMutate({ checkout, elements, stripe });
    }, [stripe, elements, checkout, submitCardPaymentMutationMutate]),
  };

  return (
    <FullScreenPageLayout>
      <div className={styles.CardPaymentView}>
        <div className={styles.header}>
          <LogoHeader
            rightElement={
              <IconButton onClick={contactUsPopup.emit} icon={<ChatIcon />} />
            }
            leftElement={
              <div className={styles.backButton}>
                <IconButton
                  onClick={onBackClick}
                  icon={
                    <div className={styles.icon}>
                      <ArrowBackIcon />
                    </div>
                  }
                />
              </div>
            }
          />
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <section className={styles.description}>
              <Typography variant="display-s">
                <Trans.Title />
              </Typography>
              <Typography variant="body-m" color="subtle">
                <Trans.SubTitle />
              </Typography>
            </section>

            <section className={styles.details}>
              <div className={styles.form} data-ready={formControls.isReady}>
                <div className={styles.paymentForm}>
                  <PaymentForm stripe={stripe} onReady={formControls.onReady} />
                </div>

                {!formControls.isReady && <PaymentFormSkeleton />}
              </div>

              <ProductPrice
                discountApplied={discountApplied}
                cart={cart}
                discountCode={discountCode}
                fullPrice={fullPrice}
              />
            </section>

            <section className={styles.cta}>
              <ActionButton
                disabled={formControls.isDisabled}
                onClick={formControls.onSubmit}
              >
                <Trans.CTA />
              </ActionButton>
            </section>
          </div>
        </div>
      </div>
    </FullScreenPageLayout>
  );
}
