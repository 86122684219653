import { localize } from "render/context/InternationalizationContext";

const Title = {
  NotReady: localize({
    en_GB: "Are you ready?",
    sv_SE: "Är du redo för besöket?",
  }),
  Ready: localize({
    en_GB: "You're all set!",
    sv_SE: "Du är redo för ditt besök!",
  }),
};

const Description = {
  NotReady: localize({
    en_GB: "Please complete this checklist before your scheduled scan time.",
    sv_SE: "Slutför din profil innan din scan.",
  }),
  Ready: localize({
    en_GB: "You're ready for your scan.",
    sv_SE: "Nu är du redo för din scan.",
  }),
};

const CTA = {
  Done: localize({
    en_GB: "Close",
    sv_SE: "Stäng",
  }),
  ToDo: localize({
    en_GB: "OK, let's go",
    sv_SE: "OK, då börjar vi",
  }),
};

const TodoList = {
  Done: {
    CompleteOnboarding: localize({
      en_GB: "Answered health questions",
      sv_SE: "Besvarat hälsoformulär",
    }),
    IdentityVerification: localize({
      en_GB: "Verified identity",
      sv_SE: "Verifierat identitet",
    }),
    Passkey: localize({
      en_GB: "Setup secure Passkey",
      sv_SE: "Lagt till Passkey",
    }),
    RenewStudies: localize({
      en_GB: "Agreed to clinical studies",
      sv_SE: "Samtyckt till studier",
    }),
  },
  Todo: {
    CompleteOnboarding: localize({
      en_GB: "Answer health questions",
      sv_SE: "Besvara hälsoformulär",
    }),
    IdentityVerification: localize({
      en_GB: "Verify identity",
      sv_SE: "Verifiera identitet",
    }),
    Passkey: localize({
      en_GB: "Setup secure Passkey",
      sv_SE: "Lägg till Passkey",
    }),
    RenewStudies: localize({
      en_GB: "Agree to clinical studies",
      sv_SE: "Samtyck till studier",
    }),
  },
};

export const Trans = {
  CTA,
  Description,
  Title,
  TodoList,
};
