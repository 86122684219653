import { Typography } from "@cur8/maneki";
import { parseHyperlinks } from "./parseHyperlinks";
import { sanitize } from "./sanitize";

// NOTE: intentionally built in-house because we only need to support
//       a very limited set of Markdown.
//       There may come a time when it makes sense to throw this away
//       and use a fully implemented Markdown parser from a 3rd-party.
export function parseMarkdown(text: string) {
  return sanitize(text)
    .split("\n\n")
    .map((line) => {
      switch (line.slice(0, 2)) {
        case "# ":
          return (
            <Typography key={line} variant="title-s" color="default">
              {parseHyperlinks(line.slice(2))}
            </Typography>
          );

        // nested lists are not supported
        case "- ":
        case "* ":
        case "+ ":
          return (
            <ul key={line}>
              {line.split("\n").map((item) => (
                <li key={item}>{parseHyperlinks(item.slice(2))}</li>
              ))}
            </ul>
          );

        default:
          return <p key={line}>{parseHyperlinks(line)}</p>;
      }
    });
}
