import type { FollowUpMessage, ImmutableVisitSummary } from "@cur8/rich-entity";
import { desc } from "lib/sort";
import type { DateTime } from "luxon";
import { useMemo } from "react";
import { useAppointmentSummariesQuery } from "render/hooks/api/queries/useAppointmentSummariesQuery";
import * as Trans from "./trans";

export function useSummaries() {
  const { data = [] } = useAppointmentSummariesQuery();

  return useMemo(
    () => data.map(toSummary).filter((s) => s.messages.length),
    [data]
  );
}

type Summary = {
  lastUpdatedAt?: DateTime;
  messages: Message[];
  visitId: string;
};

type Message = {
  date?: DateTime;
  doctorUri?: string;
  id: string;
  text: string;
  title: string | JSX.Element;
};

function toSummary(ivs: ImmutableVisitSummary): Summary {
  return {
    lastUpdatedAt: ivs.audit.lastModified?.timeStamp,
    messages: [
      ...ivs.followUpMessages
        .map(toMessage)
        .filter((m) => m.text)
        .toSorted(desc((m) => m.date)),
      {
        date: ivs.visitDate,
        doctorUri: ivs.audit.created?.userUri?.toString(),
        id: ivs.itemVersion,
        text: ivs.summaryText,
        title: <Trans.BodyScan />,
      },
    ],
    visitId: ivs.visitId,
  };
}

function toMessage(fum: FollowUpMessage): Message {
  return {
    date: fum.createdAuditRecord.timeStamp,
    doctorUri: fum.createdAuditRecord.userUri?.toString(),
    id: fum.notificationId,
    text: fum.body,
    title: fum.title,
  };
}
