import { HeartRate } from "@cur8/health-risks-calc";
import { useQueryParams } from "@pomle/react-router-paths";
import { withDecimals } from "lib/math";
import { queries } from "render/routes/paths";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { MetricDetails } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/Details";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useHeartRateMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

export function MetricHeartRateDetails() {
  const { data } = useHeartRateMetric();

  const [params, setParams] = useQueryParams(queries.metric);
  const numOfScans = data?.length || 1;
  const scanNum = params.scan[0] || numOfScans;
  const setScanNum = (num: number) =>
    setParams({
      scan: [num],
    });

  if (!data) {
    return undefined;
  }

  const { cur: current, prev: previous } = getMetricHistory(data, scanNum);

  const riskRanges = HeartRate.rangesFor();

  const values = {
    metricRating: current && riskRanges.findRisk(current.unit),
    previousValue: previous?.unit["bpm"]
      ? withDecimals(previous?.unit["bpm"], 0)
      : undefined,
    ranges: toChartRanges(riskRanges.entries),
    scanDate: current?.measurement.timestampStart,
    subLabel: "bpm",
    value: current && withDecimals(current.unit["bpm"], 0),
  };

  return (
    <MetricDetails
      label={<Trans.MetricName />}
      values={values}
      scanNum={scanNum}
      setScanNum={setScanNum}
      numOfScans={numOfScans}
      FAQText={Trans.FAQ}
      hideChange
    />
  );
}
