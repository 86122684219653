import { Typography } from "@cur8/maneki";
import { DateTime } from "luxon";
import type { ReactNode } from "react";
import { DateReadable } from "render/ui/format/DateReadable";
import { FreeText } from "render/ui/presentation/FreeText";
import { DoctorCredentials } from "render/ui/presentation/doctor/DoctorCredentials";
import { NoDoctorCredentials } from "render/ui/presentation/doctor/NoDoctorCredentials";
import styles from "./styles.module.sass";

export type SummaryProps = {
  date?: DateTime;
  doctorUri?: string | null;
  text: string;
  title?: ReactNode;
};

export function Summary({ date, doctorUri, text, title }: SummaryProps) {
  return (
    <div className={styles.Summary}>
      {(!!title || !!date) && (
        <div className={styles.header}>
          {!!title && <Typography variant="title-l">{title}</Typography>}
          {!!date && (
            <Typography variant="body-s" color="subtle">
              <DateReadable date={date} />
            </Typography>
          )}
        </div>
      )}
      <FreeText text={text} />
      {doctorUri ? (
        <DoctorCredentials doctorURI={doctorUri} />
      ) : (
        <NoDoctorCredentials />
      )}
    </div>
  );
}
