import { Typography } from "@cur8/maneki";
import { parseMarkdown } from "lib/markdown";
import styles from "./styles.module.sass";

interface FreeTextProps {
  text: string;
}

export function FreeText({ text }: FreeTextProps) {
  return (
    <div className={styles.FreeText}>
      <Typography
        as="span"
        variant="body-m"
        color="subtle"
        whiteSpace="pre-wrap"
      >
        {parseMarkdown(text)}
      </Typography>
    </div>
  );
}
