import { Typography } from "@cur8/maneki";
import { ComponentProps, ReactNode } from "react";
import styles from "./styles.module.sass";

type MetricGroupProps = {
  children: ReactNode;
  /** @default 'group' */
  kind?: "group" | "subgroup";
  title: ReactNode;
};

export function MetricGroup({
  children,
  kind = "group",
  title,
}: MetricGroupProps) {
  return (
    <section className={styles.MetricGroup} data-kind={kind}>
      <Typography variant={kindToGroup[kind]}>{title}</Typography>
      <div className={styles.cards}>{children}</div>
    </section>
  );
}

const kindToGroup: Record<Kind, TypographyProps> = {
  group: "title-l",
  subgroup: "title-s",
};

type Kind = Exclude<MetricGroupProps["kind"], undefined>;

// TODO: remove and import from @cur8/maneki when available
type TypographyProps = ComponentProps<typeof Typography>["variant"];
