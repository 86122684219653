import { ABI as ABICalc } from "@cur8/formulas";
import { ABI } from "@cur8/health-risks-calc";
import { Metric } from "lib/api/metrics";
import { withDecimals } from "lib/math";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";
import { getHighestBloodPressureValue } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/lib/getHighestBloodPressureValue";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import {
  useAnklePressureMetric,
  useBrachialPressureMetric,
} from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";

interface CalculateABIRiskProps {
  highestBrachialPressure:
    | Metric<"cardio.brachial_pressure.left">
    | Metric<"cardio.brachial_pressure.right">;
  anklePressure:
    | Metric<"cardio.ankle_pressure.left">
    | Metric<"cardio.ankle_pressure.right">;
}

function calculateABIRiskLeft({
  highestBrachialPressure,
  anklePressure,
}: CalculateABIRiskProps) {
  const score = ABICalc.calculate({
    highestAnklePressure: {
      mmHg: anklePressure.unit.mmHg,
    },
    highestSystolicBrachialPressure: {
      systolic: {
        mmHg: highestBrachialPressure.unit.systolic.mmHg,
      },
    },
  }).abi;

  const riskRanges = ABI.rangesFor();
  const risk = riskRanges.findRisk({ ratio: score });
  return risk;
}

type useABIValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useABIValues({ scanNum, side }: useABIValuesType) {
  const { data: brachialPressure } = useBrachialPressureMetric();
  const { data: anklePressure } = useAnklePressureMetric();

  if (!brachialPressure || !anklePressure) {
    return undefined;
  }

  const { cur: leftBrachialPressure, prev: prevLeftBrachialPressure } =
    getMetricHistory(brachialPressure.left, scanNum);
  const { cur: rightBrachialPressure, prev: prevRightBrachialPressure } =
    getMetricHistory(brachialPressure.right, scanNum);

  const highestBrachialPressure = getHighestBloodPressureValue(
    leftBrachialPressure,
    rightBrachialPressure
  );

  const prevHighestBrachialPressure = getHighestBloodPressureValue(
    prevLeftBrachialPressure,
    prevRightBrachialPressure
  );

  const riskRanges = ABI.rangesFor();

  if (side === "left") {
    const { cur: leftAnklePressure, prev: prevLeftAnklePressure } =
      getMetricHistory(anklePressure.left, scanNum);

    const risk =
      leftAnklePressure && highestBrachialPressure
        ? calculateABIRiskLeft({
            anklePressure: leftAnklePressure,
            highestBrachialPressure,
          })
        : undefined;
    const score =
      leftAnklePressure && highestBrachialPressure
        ? ABICalc.calculate({
            highestAnklePressure: {
              mmHg: leftAnklePressure.unit.mmHg,
            },
            highestSystolicBrachialPressure: {
              systolic: {
                mmHg: highestBrachialPressure.unit.systolic.mmHg,
              },
            },
          }).abi
        : undefined;

    const prevScore =
      prevLeftAnklePressure && prevHighestBrachialPressure
        ? ABICalc.calculate({
            highestAnklePressure: {
              mmHg: prevLeftAnklePressure.unit.mmHg,
            },
            highestSystolicBrachialPressure: {
              systolic: {
                mmHg: prevHighestBrachialPressure.unit.systolic.mmHg,
              },
            },
          }).abi
        : undefined;

    return {
      metricRating: risk,
      previousValue: prevScore && withDecimals(prevScore, 2),
      ranges: toChartRanges(riskRanges.entries),
      scanDate: leftAnklePressure?.measurement.timestampStart,
      subLabel: undefined,
      value: score && withDecimals(score, 2),
    };
  }

  const { cur: rightAnklePressure, prev: prevRightAnklePressure } =
    getMetricHistory(anklePressure.right, scanNum);

  const risk =
    rightAnklePressure && highestBrachialPressure
      ? calculateABIRiskLeft({
          anklePressure: rightAnklePressure,
          highestBrachialPressure,
        })
      : undefined;
  const score =
    rightAnklePressure && highestBrachialPressure
      ? ABICalc.calculate({
          highestAnklePressure: {
            mmHg: rightAnklePressure.unit.mmHg,
          },
          highestSystolicBrachialPressure: {
            systolic: {
              mmHg: highestBrachialPressure.unit.systolic.mmHg,
            },
          },
        }).abi
      : undefined;

  const prevScore =
    prevRightAnklePressure && prevHighestBrachialPressure
      ? ABICalc.calculate({
          highestAnklePressure: {
            mmHg: prevRightAnklePressure.unit.mmHg,
          },
          highestSystolicBrachialPressure: {
            systolic: {
              mmHg: prevHighestBrachialPressure.unit.systolic.mmHg,
            },
          },
        }).abi
      : undefined;

  return {
    metricRating: risk,
    previousValue: prevScore && withDecimals(prevScore, 2),
    ranges: toChartRanges(riskRanges.entries),
    scanDate: rightAnklePressure?.measurement.timestampStart,
    subLabel: undefined,
    value: score && withDecimals(score, 2),
  };
}
