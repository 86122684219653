import { Risk } from "@cur8/health-risks-calc";
import { GripStrength } from "@cur8/reference-data";
import { Sex } from "@cur8/rich-entity";
import { calcAge } from "lib/age";
import { DateTime } from "luxon";
import { usePatientQuery } from "render/hooks/api/queries/usePatientQuery";
import { getMetricHistory } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/metrics/utils/getMetricHistory";
import { useGripStrengthMetric } from "render/views/Report/AppointmentDataView/components/MetricsSection/hooks/usePatientReportMetrics";
import { Trans } from "./trans";

function inRange(val: number, start: number, end: number) {
  return val >= start && val <= end;
}

type useGripStrengthValuesType = {
  scanNum?: number;
  side: "left" | "right";
};

export function useGripStrengthValues({
  scanNum,
  side,
}: useGripStrengthValuesType) {
  const { data: patient } = usePatientQuery();

  const { data } = useGripStrengthMetric();

  if (!data || !patient) {
    return undefined;
  }

  const { cur: curLeft, prev: prevLeft } = getMetricHistory(data.left, scanNum);
  const { cur: curRight, prev: prevRight } = getMetricHistory(
    data.right,
    scanNum
  );

  const age = patient.dateOfBirth
    ? calcAge(patient.dateOfBirth, DateTime.now())
    : NaN;

  const prevGripStrengthLeft = prevLeft?.unit.kilograms;
  const prevGripStrengthRight = prevRight?.unit.kilograms;

  const valueLeft = curLeft?.unit.kilograms;
  const valueRight = curRight?.unit.kilograms;

  const curScanDateLeft = curLeft?.measurement.timestampStart;
  const curScanDateRight = curRight?.measurement.timestampStart;

  const gripStrengthReference =
    patient.sex === Sex.Female ? GripStrength.Female : GripStrength.Male;
  const reference = gripStrengthReference.find((chunk) =>
    inRange(age, chunk.age.from, chunk.age.to)
  );

  const referenceValue = reference?.dominant.average;
  const sideGripStrength =
    side === "left" ? curLeft?.unit.kilograms : curRight?.unit.kilograms;
  const offset =
    sideGripStrength && referenceValue
      ? sideGripStrength - referenceValue
      : NaN;

  const metricRating = offset < 0 ? Risk.Risk : Risk.Normal;

  if (side === "left") {
    return {
      metricRating,
      previousValue: prevGripStrengthLeft,
      ranges: undefined,
      scanDate: curScanDateLeft,
      subLabel: <Trans.MetricUnit />,
      value: valueLeft,
    };
  }

  if (side === "right") {
    return {
      metricRating,
      previousValue: prevGripStrengthRight,
      ranges: undefined,
      scanDate: curScanDateRight,
      subLabel: <Trans.MetricUnit />,
      value: valueRight,
    };
  }
}
