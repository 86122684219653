import { Risk } from "@cur8/health-risks-calc";
import { Typography } from "@cur8/maneki";
import { Fragment, ReactNode } from "react";
import { Badge } from "render/ui/presentation/Badge";
import { Skeleton } from "render/ui/presentation/Skeleton";
import { useBadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/hooks/useBadgeState";
import { BadgeState } from "render/views/Report/AppointmentDataView/components/MetricsSection/components/layouts/types";
import { Trans } from "../trans";
import styles from "./styles.module.sass";

type MetricProps = {
  /**
   * Tuples of `[number, unit of measure]`.
   * If a single value is provided, it's assumed to be the unit of measure and will form a tuple with `[value, description]`.
   * @example
   * [[45, "kg"]]
   * [[6, "'"], [2, "''"]]
   * [[13, "stone"], 5, "lbs"]
   * "kgs" // [[value, "kgs"]]
   */
  description?: ReactNode | [ReactNode, ReactNode][];
  label: ReactNode;
  metricRating: Risk | undefined;
  previousValue: number | undefined;
  showPreviousInsteadOfChange?: boolean;
  value: number | undefined;
};

type MetricComponentProps = {
  badgeState: BadgeState | undefined;
  /**
   * Tuples of `[number, unit of measure]`.
   * @example
   * [[45, "kg"]]
   * [[6, "'"], [2, "''"]]
   * [[13, "stone"], 5, "lbs"]
   */
  description: [ReactNode, ReactNode][];
  previousValueText?: ReactNode[];
} & Pick<MetricProps, "label">;

function MetricComponent({
  badgeState,
  description,
  label,
  previousValueText,
}: MetricComponentProps) {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography whiteSpace="nowrap" variant="label-s" color="default">
          {label}
        </Typography>
        {!!badgeState && (
          <Badge variant={badgeState.variant}>{badgeState.text}</Badge>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.values}>
          {description.map(([number, unit], index) => (
            <Fragment key={index}>
              <Typography variant="numeral-s" color="default">
                {number}
              </Typography>
              {unit && (
                <Typography variant="body-s" color="subtle">
                  {unit}
                </Typography>
              )}
            </Fragment>
          ))}
        </div>
        {!!previousValueText && (
          <div className={styles.note}>
            {previousValueText.map((val, index) => (
              <Typography variant="body-xs" color="subtle" key={index}>
                {val}
              </Typography>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export function MetricLoading({ label }: Pick<MetricProps, "label">) {
  return (
    <div className={styles.container}>
      <Typography whiteSpace="nowrap" variant="body-s">
        {label}
      </Typography>
      <div className={styles.skeleton}>
        <Skeleton />
      </div>
    </div>
  );
}

export function WeightMetric({
  description,
  label,
  note,
}: {
  label: ReactNode;
  description: [ReactNode, ReactNode][];
  note?: ReactNode[];
}) {
  return (
    <MetricComponent
      badgeState={undefined}
      label={label}
      description={description}
      previousValueText={note}
    />
  );
}

export function Metric({
  description,
  label,
  metricRating,
  previousValue,
  showPreviousInsteadOfChange,
  value,
}: MetricProps) {
  const badgeState = useBadgeState({ metricRating, value });

  return (
    <MetricComponent
      label={label}
      badgeState={badgeState}
      description={
        Array.isArray(description) ? description : [[value ?? "-", description]]
      }
      previousValueText={[
        getPreviousValueText({
          previousValue,
          showPreviousInsteadOfChange,
          value,
        }),
      ]}
    />
  );
}

type GetPreviousValueTextProps = Pick<
  MetricProps,
  "previousValue" | "showPreviousInsteadOfChange" | "value"
>;

function getPreviousValueText({
  previousValue,
  showPreviousInsteadOfChange,
  value,
}: GetPreviousValueTextProps) {
  if (previousValue == null) {
    return;
  }

  if (value == null || showPreviousInsteadOfChange) {
    return <Trans.Previous value={previousValue} />;
  }

  const change = value - previousValue;
  const changeNumber = +Math.abs(change).toFixed(2);

  if (change >= 0) {
    return <Trans.Increased value={changeNumber} />;
  }

  return <Trans.Decreased value={changeNumber} />;
}
