import { APITypesV2 } from "@cur8/api-client";
import { Patient, Visit } from "@cur8/rich-entity";
import { Sticky, ViewStack } from "@pomle/react-viewstack";
import { mapConsents } from "lib/consents/mapConsents";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { FullScreenPageLayout } from "render/ui/layout/FullScreenPageLayout";
import { LogoHeader } from "render/ui/layout/LogoHeader/LogoHeader";
import { ConsentRequiredStudiesExplanation } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/CheckInFlowView/components/ConsentRequiredStudiesExplanation";
import { QuestionnaireIntro } from "render/views/concierge/ConciergeCheckInView/components/ConciergeCheckInSessionView/components/CheckInFlowView/components/QuestionnaireIntro";
import { OnboardingFlowView } from "render/views/OnboardingView/components/OnboardingFlowView";
import { Transition } from "../__shared/Transition";
import { CheckInCompleteStep } from "./components/CheckInCompleteStep";
import { CheckInConsentDocument } from "./components/CheckInConsentDocument";
import { ConsentIntro } from "./components/ConsentIntro";
import styles from "./styles.module.sass";

interface CheckInFlowProps {
  onActive?: () => void;
  patient: Patient;
  duringVisitSelfSignConsents: APITypesV2.ConsentMetadata[] | undefined;
  patientConsents: APITypesV2.ConsentWithSignature[];
  isQuestionnaireComplete: boolean;
  visitId: Visit["visitId"];
}

const Step = {
  Complete: "Complete",
  OnboardingQuestionnaire: "OnboardingQuestionnaire",
  OnboardingQuestionnaireIntro: "OnboardingQuestionnaireIntro",
  RequiresConsentExplanation: "RequiresConsentExplanation",
  StudyConsentExplanation: "StudyConsentExplanation",
};

interface View {
  key: string;
  component: () => ReactElement;
}

type Consent = {
  /** Description of a consent */
  consentMetadata: APITypesV2.ConsentMetadata;
  /** A summary of a signed consent */
  relevantSignature: APITypesV2.ConsentSummary | undefined;
};

function checkInConsentDocuments(consents: Consent[] | undefined): View[] {
  return (
    consents?.map<View>((consent) => {
      return {
        component: () => <CheckInConsentDocument consent={consent} />,
        key: consent.consentMetadata.consentId,
      };
    }) ?? []
  );
}

export function CheckInFlow({
  patient,
  duringVisitSelfSignConsents,
  patientConsents,
  isQuestionnaireComplete,
  onActive,
  visitId,
}: CheckInFlowProps) {
  useEffect(() => {
    onActive?.();
  }, [onActive]);

  const { trackEvent } = useAppInsights();
  const [showStudyExplanation, setShowStudyExplanation] = useState(true);
  const [showConsentExplanation, setShowConsentExplanation] = useState(true);
  const [showQuestionnaireIntro, setShowQuestionnaireIntro] = useState(
    !isQuestionnaireComplete
  );
  const [showQuestionnaire, setShowQuestionnaire] = useState(
    !isQuestionnaireComplete
  );

  const mappedConsents = mapConsents({
    previouslySignedConsents: patientConsents,
    requiredConsents: duringVisitSelfSignConsents,
  });

  const studyConsents = mappedConsents?.filter(
    (consent) =>
      consent.consentMetadata.consentType === APITypesV2.ConsentType.Study
  );
  const productAndOtherConsents = mappedConsents?.filter(
    (consent) =>
      consent.consentMetadata.consentType !== APITypesV2.ConsentType.Study
  );

  const unsignedStudyConsentKeys = useMemo(() => {
    return studyConsents
      ?.filter(
        (consent) =>
          consent.relevantSignature == null ||
          consent.relevantSignature?.expired ||
          consent.relevantSignature?.revoked ||
          consent.consentMetadata.askConsentEveryTime
      )
      .map((consent) => consent.consentMetadata.consentId);
  }, [studyConsents]);

  const unsignedProductAndOtherConsentKeys = useMemo(() => {
    return productAndOtherConsents
      ?.filter(
        (consent) =>
          consent.relevantSignature == null ||
          consent.relevantSignature?.expired ||
          consent.relevantSignature?.revoked ||
          consent.consentMetadata.askConsentEveryTime
      )
      .map((consent) => consent.consentMetadata.consentId);
  }, [productAndOtherConsents]);

  const studyConsentSteps = useMemo(() => {
    return checkInConsentDocuments(studyConsents);
  }, [studyConsents]);

  const productAndOtherConsentSteps = useMemo(() => {
    return checkInConsentDocuments(productAndOtherConsents);
  }, [productAndOtherConsents]);

  const steps = useMemo<View[]>(() => {
    const result: View[] = [];
    if (studyConsentSteps != null) {
      result.push(
        {
          component: () => (
            <ConsentRequiredStudiesExplanation
              onDone={() => {
                setShowStudyExplanation(false);
              }}
            />
          ),
          key: Step.StudyConsentExplanation,
        },
        ...studyConsentSteps
      );
    }

    if (productAndOtherConsentSteps != null) {
      result.push(
        {
          component: () => (
            <ConsentIntro onDone={() => setShowConsentExplanation(false)} />
          ),
          key: Step.RequiresConsentExplanation,
        },

        ...productAndOtherConsentSteps
      );
    }

    if (showQuestionnaireIntro) {
      result.push({
        component: () => (
          <QuestionnaireIntro onDone={() => setShowQuestionnaireIntro(false)} />
        ),
        key: Step.OnboardingQuestionnaireIntro,
      });
    }

    if (showQuestionnaire) {
      result.push({
        component: () => (
          <OnboardingFlowView
            onNavBack={() => setShowQuestionnaireIntro(true)}
            onSuccess={() => setShowQuestionnaire(false)}
            showIntro={false}
            visitId={visitId}
          />
        ),
        key: Step.OnboardingQuestionnaire,
      });
    }

    result.push({
      component: () => <CheckInCompleteStep patient={patient} />,
      key: Step.Complete,
    });

    return result;
  }, [
    patient,
    productAndOtherConsentSteps,
    showQuestionnaire,
    showQuestionnaireIntro,
    studyConsentSteps,
    visitId,
  ]);

  const currentStep = useMemo(() => {
    if (unsignedStudyConsentKeys && unsignedStudyConsentKeys.length > 0) {
      if (showStudyExplanation) {
        return Step.StudyConsentExplanation;
      }
      return unsignedStudyConsentKeys[0];
    }

    if (
      unsignedProductAndOtherConsentKeys &&
      unsignedProductAndOtherConsentKeys.length > 0
    ) {
      if (showConsentExplanation) {
        return Step.RequiresConsentExplanation;
      }
      return unsignedProductAndOtherConsentKeys[0];
    }

    if (showQuestionnaireIntro) {
      return Step.OnboardingQuestionnaireIntro;
    }

    if (showQuestionnaire) {
      return Step.OnboardingQuestionnaire;
    }

    return Step.Complete;
  }, [
    showConsentExplanation,
    showQuestionnaire,
    showQuestionnaireIntro,
    showStudyExplanation,
    unsignedProductAndOtherConsentKeys,
    unsignedStudyConsentKeys,
  ]);

  const currentStepIndex = useMemo(() => {
    return steps.findIndex(({ key }) => key === currentStep);
  }, [steps, currentStep]);

  useEffect(() => {
    trackEvent({
      name: "concierge-uk-check-in-success",
      properties: { step: currentStep },
    });
  }, [currentStep, trackEvent]);

  return (
    <FullScreenPageLayout background="transparent">
      <div className={styles.CheckInFlowView}>
        <ViewStack>
          {steps.map(({ key, component }, index) => {
            const match = key === currentStep;
            const offset = index - currentStepIndex;
            return (
              <Transition offset={offset} key={key}>
                <Sticky delay={300}>{match && component()}</Sticky>
              </Transition>
            );
          })}
        </ViewStack>
        {/* hide the header so it doesn't clash with the header in the questionnaire */}
        {currentStep !== Step.OnboardingQuestionnaire ? (
          <div className={styles.header}>
            <LogoHeader />
          </div>
        ) : undefined}
      </div>
    </FullScreenPageLayout>
  );
}
